export const TABS = {
    COMPANY_DATA: 1,
    RESPONSIBLE_DATA: 2,
    CONTACTS: 3,
    BANK_DATA: 4,
    BENEFITS: 5,
    ACCESS: 6
}

export const QUERY_TAB = {
    company_data: 1,
    responsible_data: 2,
    contacts: 3,
    bank_data: 4,
    benefits: 5,
    access: 6
}

export const SupplierStatus = Object.freeze({
    PROSPECT: { name: 'PROSPECT', description: 'Prospecção', color: '#fe7333' },
    QUALIFIED: { name: 'QUALIFIED', description: 'Acessado', color: '#ffc863' },
    UNDER_REVIEW: { name: 'UNDER_REVIEW', description: 'Em Revisão', color: '#2a9df4' },
    SINGULARE_ANALYSIS: { name: 'SINGULARE_ANALYSIS', description: 'Em Revisão', color: '#2a9df4' },
    ACTIVE: { name: 'ACTIVE', description: 'Ativo', color: '#69ce69' },
    CONVERTED: { name: 'CONVERTED', description: 'Ativo', color: '#69ce69' }
})

export const SupplierSize = Object.freeze({
    MICRO: { name: 'MICRO', description: 'Micro' },
    SMALL: { name: 'SMALL', description: 'Pequeno' },
    MEDIUM: { name: 'MEDIUM', description: 'Médio' },
    LARGE: { name: 'LARGE', description: 'Grande' }
})

export const SupplierSegment = Object.freeze({
    AGRICULTURE: { name: 'AGRICULTURE', description: 'Agricultura' },
    FOOD: { name: 'FOOD', description: 'Alimentos' },
    COMMERCE: { name: 'COMMERCE', description: 'Comércio' },
    PHARMACY: { name: 'PHARMACY', description: 'Farmácia' },
    FINANCIAL: { name: 'FINANCIAL', description: 'Financeiro' },
    SMOKING: { name: 'SMOKING', description: 'Fumo' },
    HEALTHCARE: { name: 'HEALTHCARE', description: 'Hospitalares' },
    REAL_ESTATE: { name: 'REAL_ESTATE', description: 'Imobiliário' },
    INDUSTRY: { name: 'INDUSTRY', description: 'Indústria' },
    INSTALLATION: { name: 'INSTALLATION', description: 'Instalação' },
    METAL: { name: 'METAL', description: 'Metálicos' },
    PAPER: { name: 'PAPER', description: 'Papelaria' },
    OIL: { name: 'OIL', description: 'Petrolífero' },
    PRIMARY: { name: 'PRIMARY', description: 'Primário' },
    SERVICES: { name: 'SERVICES', description: 'Serviços' },
    TRANSPORT: { name: 'TRANSPORT', description: 'Transporte' }
})

export const StateList = [
    {
        name: 'AC',
        description: 'Acre - AC'
    },
    {
        name: 'AL',
        description: 'Alagoas - AL'
    },
    {
        name: 'AP',
        description: 'Amapá - AP'
    },
    {
        name: 'AM',
        description: 'Amazonas - AM'
    },
    {
        name: 'BA',
        description: 'Bahia - BA'
    },
    {
        name: 'CE',
        description: 'Ceará - CE'
    },
    {
        name: 'DF',
        description: 'Distrito Federal - DF'
    },
    {
        name: 'ES',
        description: 'Espírito Santo - ES'
    },
    {
        name: 'GO',
        description: 'Goiás - GO'
    },
    {
        name: 'MA',
        description: 'Maranhão - MA'
    },
    {
        name: 'MT',
        description: 'Mato Grosso - MT'
    },
    {
        name: 'MS',
        description: 'Mato Grosso do Sul - MS'
    },
    {
        name: 'MG',
        description: 'Minas Gerais - MG'
    },
    {
        name: 'PA',
        description: 'Pará - PA'
    },
    {
        name: 'PB',
        description: 'Paraíba - PB'
    },
    {
        name: 'PR',
        description: 'Paraná - PR'
    },
    {
        name: 'PE',
        description: 'Pernambuco - PE'
    },
    {
        name: 'PI',
        description: 'Piauí - PI'
    },
    {
        name: 'RJ',
        description: 'Rio de Janeiro - RJ'
    },
    {
        name: 'RN',
        description: 'Rio Grande do Norte - RN'
    },
    {
        name: 'RS',
        description: 'Rio Grande do Sul - RS'
    },
    {
        name: 'RO',
        description: 'Rondônia - RO'
    },
    {
        name: 'RR',
        description: 'Roraima - RR'
    },
    {
        name: 'SC',
        description: 'Santa Catarina - SC'
    },
    {
        name: 'SP',
        description: 'São Paulo - SP'
    },
    {
        name: 'SE',
        description: 'Sergipe - SE'
    },
    {
        name: 'TO',
        description: 'Tocantis - TO'
    }
]

export const DocumentType = Object.freeze({
    SOCIAL_CONTRACT: {
        name: 'SOCIAL_CONTRACT',
        description: 'Contrato ou Estatuto Social',
        fileName: 'contrato_social'
    },
    RG_CNH: {
        name: 'RG_CNH',
        description: 'Documento de Identificação (RG com CPF ou CNH)',
        fileName: 'rg_cnh'
    },
    LETTER_OF_ATTORNEY: {
        name: 'LETTER_OF_ATTORNEY',
        description: 'Procuração (caso necessário)',
        fileName: 'procuraçao'
    },
    NFE: {
        name: 'NFE:',
        description: 'Nota fiscal',
        fileName: 'nfe'
    },
    RG_CNH_FRONT: {
        name: 'RG_CNH_FRONT',
        description: 'Documento de Identificação (RG com CPF ou CNH) - Frente',
        fileName: 'rg_cnh_frente'
    },
    RG_CNH_BACK: {
        name: 'RG_CNH_BACK',
        description: 'Documento de Identificação (RG com CPF ou CNH) - Verso',
        fileName: 'rg_cnh_verso'
    },
    RG_FRONT: {
        name: 'RG_FRONT',
        description: 'Documento de Identificação (RG) - Frente',
        fileName: 'rg_frente'
    },
    RG_BACK: {
        name: 'RG_BACK',
        description: 'Documento de Identificação (RG) - Verso',
        fileName: 'rg_verso'
    },
    CNH_FRONT: {
        name: 'CNH_FRONT',
        description: 'Carteira Nacional de Habilitação (CNH) - Frente',
        fileName: 'cnh_frente'
    },
    CNH_BACK: {
        name: 'CNH_BACK',
        description: 'Carteira Nacional de Habilitação (CNH) - Verso',
        fileName: 'cnh_verso'
    },
    CNH_DIGITAL: {
        name: 'CNH_DIGITAL',
        description: 'Carteira Nacional de Habilitação (CNH) - Digital',
        fileName: 'cnh_digital'
    },
    SELFIE: {
        name: 'SELFIE',
        description: 'Foto do usuário',
        fileName: 'selfie'
    }
})

export const ResponsibleType = Object.freeze({
    REPRESENTATIVE: { name: 'REPRESENTATIVE', description: 'Representante' },
    PARTNER: { name: 'PARTNER', description: 'Sócio' },
    GUARANTOR: { name: 'GUARANTOR', description: 'Avalista' }
})

export const SignCondition = Object.freeze({
    FULLY_SIGNS: { name: 'FULLY_SIGNS', description: 'Sozinho' },
    PARTIALLY_SIGNS: { name: 'PARTIALLY_SIGNS', description: 'Em termos' },
    NO_SIGNATURE: { name: 'NO_SIGNATURE', description: 'Não assina' }
})

export const DEFAULT_ERROR_MSG =
    'Houve um problema de conexão com nossos servidores, tente novamente'

export const SUCCESS_ALERT_TIMEOUT = 5 * 1000

export const MAX_YEARLY_GROSS_REVENUE_LENGTH = 17
