import {
    ROUTE_DASHBOARD,
    ROUTE_ANTICIPATIONS,
    ROUTE_INVOICES,
    ROUTE_INVOICES_APPLICATIONS,
    ROUTE_REFERRAL_LANDING,
    ROUTE_REFERRAL_ACHIEVEMENTS
} from '@/config/routes'
import iconInvoices from './../../public/images/icon_sm_invoice.svg'
import iconInvoicesDark from './../../public/images/icon_sm_invoice_dark.svg'
import iconHome from './../../public/images/icon_sm_home.svg'
import iconHomeDark from './../../public/images/icon_sm_home_dark.svg'
import iconSupply from './../../public/images/icon_sm_money.svg'
import iconSupplyDark from './../../public/images/icon_sm_money_dark.svg'

export const MENU = [
    {
        label: 'Início',
        path: ROUTE_DASHBOARD,
        id: 'dashboard',
        icon: iconHome.src,
        iconDark: iconHomeDark.src,
        roles: null
    },
    {
        label: 'Notas',
        icon: iconInvoices.src,
        iconDark: iconInvoicesDark.src,
        path: '/invoices',
        id: 'invoices',
        smallIcon: true,
        items: [
            { label: 'Minhas notas', path: ROUTE_INVOICES, id: 'invoices-list' },
            {
                label: 'Solicitações de notas',
                path: ROUTE_INVOICES_APPLICATIONS,
                id: 'invoices-application'
            }
        ]
    },
    {
        label: 'Antecipações',
        icon: iconSupply.src,
        iconDark: iconSupplyDark.src,
        largeIcon: true,
        path: ROUTE_ANTICIPATIONS,
        id: 'anticipations'
    },
    {
        label: 'Programa de Indicação',
        icon: '/images/money-alt.svg',
        iconDark: '/images/money-alt_dark.svg',
        path: '/referral',
        id: 'referral',
        items: [
            {
                label: 'CondoMulti (indicações)',
                path: ROUTE_REFERRAL_LANDING,
                id: 'referral-list'
            },
            {
                label: 'Conquistas',
                path: ROUTE_REFERRAL_ACHIEVEMENTS,
                id: 'referral-achievements'
            }
        ]
    }
]
