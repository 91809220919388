import { getUserSession } from './index'
const user = getUserSession()

export const gtmShootEvent = (model) => {
    const tag = model
    tag.section = model.section ? kebabCase(model.section) : null
    tag.label = model.label ? kebabCase(model.label) : null

    if (user) {
        tag.user_Id = user.id
    }

    window.dataLayer.push(tag)
}

const kebabCase = (str) => {
    return str
        .replace(/([a-z])([A-Z])/g, '$1-$2')
        .replace(/[\s_]+/g, '-')
        .toLowerCase()
}

export const getTagScreen = (route) => {
    switch (route) {
        case '/dashboard':
            return tagScreen.SUPPLY_DASHBOARD
        case '/login':
            return tagScreen.SUPPLY_LOGIN
        case '/registration':
            return tagScreen.SUPPLY_COMPLETE_REGISTER
        case '/invoices':
            return tagScreen.SUPPLY_INVOICES
        case '/supplier':
            return tagScreen.SUPPLY_UPDATE_REGISTER_COMPANY_DATA
        case '/anticipations':
            return tagScreen.SUPPLY_ANTICIPATION
        default:
            return ''
    }
}

export const tagScreen = {
    SUPPLY_FIRST_ACCESS: 'fornecedor-primeiro-acesso',
    SUPPLY_FIRST_ACCESS_SUCCESS: 'fornecedor-primeiro-acesso-sucesso',
    SUPPLY_LOGIN: 'fornecedor-pagina-de-login',
    SUPPLY_RESET_PASSWORD_EMAIL_RETURN: 'fornecedor-redefinir-senha-retorno-email',
    SUPPLY_RESET_PASSWORD: 'fornecedor-redefinir-senha',
    SUPPLY_INSTABILITY_ERROR_PAGE: 'fornecedor-pagina-erro-instabilidade',
    SUPPLY_COMPLETE_REGISTER: 'fornecedor-pagina-completar-cadastro',
    SUPPLY_DASHBOARD: 'fornecedor-dashboard',
    SUPPLY_COMPLETE_REGISTER_MODAL: 'fornecedor-completar-cadastro-modal',
    SUPPLY_COMPLETE_REGISTER_DOCUMENTATION: 'fornecedor-documentacao-empresa',
    SUPPLY_COMPLETE_REGISTER_COMPANY_DATA: 'fornecedor-completar-dados-empresa',
    SUPPLY_COMPLETE_REGISTER_CONTACTS: 'fornecedor-completar-dados-contatos',
    SUPPLY_COMPLETE_REGISTER_BANK_DATA: 'fornecedor-completar-dados-bancarios',
    SUPPLY_COMPLETE_REGISTER_SUCCESS_MODAL: 'fornecedor-completar-dados-sucesso-modal',
    SUPPLY_INVOICES: 'fornecedor-todas-notas',
    SUPPLY_ANTICIPATION_CONFIRMATION_MODAL: 'fornecedor-modal-confirmação-antecipação',
    SUPPLY_ANTICIPATION_ERROR_PROCESSING_MODAL:
        'fornecedor-antecipação-modal-erro-de-processamento',
    SUPPLY_ANTICIPATION_CONFIRMATION_SUCESS_MODAL:
        'fornecedor-modal-sucesso-confirmação-antecipação',
    SUPPLY_ANTICIPATION: 'fornecedor-tab-antecipação',
    SUPPLY_UPDATE_REGISTER_SUCCESS_MODAL: 'fornecedor-modal-dados-atualizados-com-sucesso',
    SUPPLY_INVOICES_APPLICATION_CREATE: 'fornecedor-adicionar-nota',
    SUPPLY_INVOICES_APPLICATION_CREATE_SUCCESS: 'fornecedor-adicionar-nota-successo',
    SUPPLY_UPDATE_PASSWORD: 'fornecedor-atualizar-senha',
    SUPPLY_UPDATE_PASSWORD_SUCCESS_MODAL: 'fornecedor-modal-sucesso-solicitação'
}

export const tagSection = {
    AVAILABLE_VALUES: 'valores disponiveis',
    CONSOLIDATED_RECEIVABLES_ADMINISTRATOR: 'consolidado em recebíveis por administradora',
    HEADER: 'cabeçalho',
    DOCUMENTATION: 'documentação',
    COMPLETE_REGISTER_MODAL: 'modal completar o cadastro',
    LAST_ANTICIPATIONS: 'ultimas antecipações',
    ANTICIPATION_MODAL: 'modal de antecipação',
    ANTICIPATION_CONFERENCE_REQUEST_CONFIRMATION:
        'Antecipação Conferência e confirmação de solicitação'
}
