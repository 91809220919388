import React from 'react'
import styles from './Footer.module.scss'
import { Container, Row, Col } from 'react-grid-system'
import { Text, IconPlace, IconPhone, IconWhats, Logo } from 'condolivre-ds'
import { useBreakpoint } from '@/helpers/withBreakpoint'

const Footer = (props) => {
    const { isMobile } = useBreakpoint()

    return (
        <div className={styles.footerContainer} {...props}>
            <Container>
                <Row>
                    <Col className={styles.footerText}>
                        <Logo
                            variant={'blue'}
                            width={'168px'}
                            height={'35px'}
                            className={styles.logoFooter}
                        />
                        <Text
                            size={isMobile ? 'v_14' : 'v_16'}
                            weight="v_500"
                            color="gray_700"
                            className={styles.footerInfo}>
                            A CondoLivre Análise de Crédito e Cobrança Ltda, inscrita sob o CNPJ
                            28.314.050/0001-62, é uma {isMobile && <br />} Fintech especializada em{' '}
                            {!isMobile && <br />} soluções de crédito para o universo de
                            condomínios.
                        </Text>
                    </Col>
                </Row>
                <Row>
                    <Col lg={4} className={styles.left}>
                        <Text weight="v_500" color="gray_800" size="v_16">
                            Nosso endereço
                        </Text>
                        <Text
                            size="v_14"
                            color="gray_800"
                            weight="v_500"
                            className={[styles.tip, styles.address]}>
                            <IconPlace />
                            <span>
                                Alameda dos Jurupis, 1005, 7º Andar cj 71 a 74
                                <br />
                                Indianópolis, São Paulo - SP, 04088-003
                            </span>
                        </Text>
                    </Col>
                    <Col lg={4} className={styles.center}>
                        <Text weight="v_500" color="gray_800" size="v_16">
                            Central de atendimento
                        </Text>
                        <Text size="v_24" color="gray_800" weight="v_500" className={styles.tip}>
                            <IconPhone />
                            <span>(11) 3097-9918</span>
                        </Text>
                    </Col>
                    <Col lg={4} className={styles.right}>
                        <Text weight="v_500" color="gray_800" size="v_16">
                            WhatsApp
                        </Text>
                        <Text size="v_24" color="gray_800" weight="v_500" className={styles.tip}>
                            <IconWhats />
                            <span>(11) 96417-9978</span>
                        </Text>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default Footer
