import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import {
    Button,
    SideBar,
    SideBarMenuItem,
    SideBarMenuItemText,
    SideBarTitle,
    UserBadge,
    Text
} from 'condolivre-ds'
import styles from './MenuSideBar.module.scss'
import { getUser } from '@/helpers/cookies'
import { MENU } from '@/components/MenuSideBar/menu'
import { useRouter } from 'next/router'
import { CONDO_NEW_MENU_PREFIX } from '@/config/constants'
import { useBreakpoint } from '@/helpers/withBreakpoint'
import { ROUTE_LOGOUT, ROUTE_REGISTRATION, ROUTE_SUPPLIER } from '@/config/routes'
import iconProfileDataDark from './../../public/images/id-card_dark.svg'
import iconBankDark from './../../public/images/icon_sm_dados_bancarios_dark.svg'
import iconLogout from 'condolivre-ds/build/static/icon/icon_sm_logout.svg'
import { gtmShootEvent, tagScreen, tagSection } from '@/helpers/tag'
import { useDigitalAccountUser } from '@/providers/DigitalAccountUser'
import { formatBankAccount } from '@/helpers/format'

const shootEvent = (id) => () => {
    gtmShootEvent({
        screen: tagScreen.SUPPLY_DASHBOARD,
        label: id,
        section: tagSection.HEADER
    })
}

export const MenuSideBar = ({ isQualified, overrideMenu, overrideExit, className }) => {
    const { isDesktop } = useBreakpoint()
    const { bankingInfo } = useDigitalAccountUser()

    const router = useRouter()
    const [subMenuActive, setSubMenuActive] = useState(null)

    const user = getUser()

    const menu = useMemo(() => {
        if (Array.isArray(overrideMenu) && overrideMenu.length > 0) {
            return overrideMenu
        }
        return MENU
    }, [MENU, overrideMenu])

    const isDigitalAccount = Array.isArray(overrideMenu) && overrideMenu.length > 0

    const handleToogle = (path) => (isActive) => {
        setSubMenuActive(isActive ? path : null)
    }

    const [isHambOpen, setIsHambOpen] = useState(false)
    const hambOpenHandler = () => {
        setIsHambOpen((prevIsHambOpen) => !prevIsHambOpen)
    }

    const hasUserAlreadyClicked = (id) => {
        return !localStorage.getItem(`${CONDO_NEW_MENU_PREFIX}${id}`)
    }
    const handleClickNewMenu = (id) => () => {
        localStorage.setItem(`${CONDO_NEW_MENU_PREFIX}${id}`, 'true')
    }

    const closeMenu = () => {
        const buttonClose = document.getElementById('menu__toggle')
        buttonClose.click()
    }

    const generateNewTag = (newClassName) => {
        return (
            <div className={`${styles.newTagWrapper} ${newClassName || ''}`}>
                <div className={styles.newTag}>
                    <span>Novo</span>
                </div>
            </div>
        )
    }
    const mobileMenuItems = [
        {
            label: 'Dados cadastrais',
            icon: iconProfileDataDark.src,
            onClick: () => {
                router.push(isQualified ? ROUTE_REGISTRATION : `${ROUTE_SUPPLIER}?tab=company_data`)
                closeMenu()
            }
        },
        {
            label: 'Dados bancários',
            icon: iconBankDark.src,
            onClick: () => {
                router.push(isQualified ? ROUTE_REGISTRATION : `${ROUTE_SUPPLIER}?tab=bank_data`)
                closeMenu()
            }
        }
    ]

    return (
        <SideBar
            className={`${styles.sidebar} ${
                isDigitalAccount
                    ? styles.sidebarBackgroundLogoDigitalAccount
                    : styles.sidebarBackgroundLogoSupply
            } ${isHambOpen ? styles.SideBar__active : ''} ${className || ''}`}
            variant={'supply'}
            data-testid={'sidebar-menu'}>
            <SideBarTitle
                className={[
                    styles.sideBarTitle,
                    `${
                        isDigitalAccount
                            ? styles.sideBarTitleBackgroundDigitalAccount
                            : styles.sideBarTitleBackgroundSupply
                    }`
                ].join(' ')}>
                <div className={styles.userBadgeHeader}>
                    <UserBadge
                        color={'purple_800'}
                        name={user && user.name}
                        user
                        id={'header-user-badge'}
                        showUserInfo={false}
                        size={'small'}
                    />
                </div>
                <div className={styles.mobileHeaderWrapper}>
                    <Text className={styles.headerTitle}>
                        {isDigitalAccount ? 'Conta Digital' : 'Portal do Fornecedor'}
                    </Text>
                    {!isDesktop && isDigitalAccount && (
                        <div className={styles.mobileInfoWrapper}>
                            <div className={styles.digitalAccountHeaderInfoWrapper}>
                                <Text className={styles.digitalAccountHeaderTextDesc}>AG:</Text>

                                <Text className={styles.digitalAccountHeaderText}>
                                    {bankingInfo.agency}
                                </Text>
                            </div>
                            <div className={styles.digitalAccountHeaderInfoWrapper}>
                                <div>
                                    <Text className={styles.digitalAccountHeaderTextDesc}>CC:</Text>
                                </div>
                                <div>
                                    <Text className={styles.digitalAccountHeaderText}>
                                        {formatBankAccount(bankingInfo.number, bankingInfo.digit)}
                                    </Text>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                <div
                    tabIndex={-1}
                    role={'button'}
                    onKeyDown={() => {}}
                    onClick={hambOpenHandler}
                    className={styles.hamburger_menu}>
                    <input
                        className={styles.menu__toggle}
                        data-testid="menu__toggle"
                        id="menu__toggle"
                        type="checkbox"
                    />
                    <label className={styles.menu__btn} htmlFor="menu__toggle">
                        <span></span>
                    </label>
                </div>
            </SideBarTitle>
            {menu.map(
                (
                    {
                        label,
                        icon,
                        iconDark,
                        path,
                        items,
                        id,
                        isNew,
                        smallIcon,
                        largeIcon,
                        newClassName
                    },
                    index
                ) => {
                    const isActive = subMenuActive
                        ? subMenuActive == path
                        : router.pathname == path ||
                          (items || []).map((i) => i.path).indexOf(router.pathname) !== -1

                    const showNewTag = isNew && hasUserAlreadyClicked(id)

                    return (
                        <SideBarMenuItem
                            className={`${styles.SideBarMenuItem} ${
                                smallIcon ? styles.smallIcon : ''
                            } ${largeIcon ? styles.largeIcon : ''} ${
                                isHambOpen ? styles.SideBar__active : ''
                            } ${isActive ? styles.SideBar__selected : ''}`}
                            key={index}
                            href={items ? '#' : path}
                            label={
                                <>
                                    {label}
                                    {showNewTag && generateNewTag(newClassName)}
                                </>
                            }
                            id={id}
                            icon={isDesktop ? icon : iconDark}
                            onToogle={handleToogle(path)}
                            onClick={showNewTag ? handleClickNewMenu(id) : () => {}}
                            active={isActive}>
                            {items &&
                                items.map((item, index) => {
                                    return (
                                        <SideBarMenuItemText
                                            data-testid={`sub-item-${item.id}`}
                                            label={item.label}
                                            href={item.path}
                                            key={`sub-item-${index}`}
                                        />
                                    )
                                })}
                        </SideBarMenuItem>
                    )
                }
            )}

            {mobileMenuItems.map(({ onClick, icon, label }, index) => {
                return (
                    <SideBarMenuItem
                        className={`${styles.SideBarMenuItem} ${styles.FixedSideBarMenuItem}`}
                        key={`f${index}`}
                        label={label}
                        icon={icon}
                        onClick={onClick}></SideBarMenuItem>
                )
            })}

            <div
                className={`${styles.SideBarMenuItem} ${styles.FixedSideBarMenuItem} ${styles.exitBtnDiv}`}
                key={`exit`}>
                <a href={overrideExit?.path || ROUTE_LOGOUT}>
                    <Button
                        className={overrideExit?.className}
                        variant={'primary'}
                        size={'small'}
                        icon={iconLogout.src}
                        iconPos={'left'}
                        onClick={() => {
                            shootEvent(overrideExit?.eventName || 'btn-sair')
                        }}>
                        {overrideExit?.label || 'Sair'}
                    </Button>
                </a>
            </div>

            <div
                tabIndex={-1}
                role={'button'}
                onKeyDown={() => {}}
                className={`${styles.SideBarMenuItem} ${styles.FixedSideBarMenuItem} ${styles.menuBackground}`}
                key={`bgMenu`}
                onClick={closeMenu}></div>
        </SideBar>
    )
}

export default MenuSideBar

MenuSideBar.propTypes = {
    isQualified: PropTypes.bool,
    overrideMenu: PropTypes.array,
    overrideExit: PropTypes.object,
    className: PropTypes.string
}
