import jwt_decode from 'jwt-decode'
import Cookies from 'universal-cookie'

export const registerCookie = (token, expires_in) => {
    const cookies = new Cookies()

    cookies.set('session', token, {
        expires: expires_in,
        path: '/',
        sameSite: 'strict'
    })

    const decoded = jwt_decode(token)
    cookies.set('user', JSON.stringify(decoded), {
        expires: expires_in,
        path: '/',
        sameSite: 'strict'
    })
}

export const registerDigitalAccountCookies = (
    token,
    expires_in,
    refresh_token,
    refresh_token_expires_in,
    audit
) => {
    const cookies = new Cookies()

    cookies.set('da-session', token, {
        expires: expires_in,
        path: '/',
        sameSite: 'strict'
    })

    const decoded = jwt_decode(token)
    cookies.set('da-user', JSON.stringify(decoded), {
        expires: expires_in,
        path: '/',
        sameSite: 'strict'
    })

    refresh_token &&
        cookies.set('da-refresh', refresh_token, {
            expires: refresh_token_expires_in || expires_in,
            path: '/',
            sameSite: 'strict'
        })

    audit &&
        cookies.set('da-audit', audit, {
            expires: refresh_token_expires_in || expires_in,
            path: '/',
            sameSite: 'strict'
        })
}

export const registerLelloCookies = (token) => {
    const cookies = new Cookies()

    const decoded = jwt_decode(token)
    const expiresIn = new Date(1970, 0, 1)
    expiresIn.setSeconds(decoded.exp)

    cookies.set('session', token, {
        expires: expiresIn,
        path: '/',
        sameSite: 'strict'
    })
}

export const registerUser = (data, expires_in) => {
    const cookies = new Cookies()
    cookies.set('userData', JSON.stringify(data), {
        expires: expires_in,
        path: '/',
        sameSite: 'strict'
    })
}

export const getUser = () => {
    const cookies = new Cookies()
    return cookies.get('userData')
}

export const getDigitalAccountUser = () => {
    const cookies = new Cookies()
    return cookies.get('da-user')
}

export const patchUser = (data = {}) => {
    const userData = getUser()
    registerUser({
        ...userData,
        ...data
    })
}

export const registerDismissedMessage = (type) => {
    const cookies = new Cookies()
    cookies.set('dismissedMessage', JSON.stringify(type), {
        expires: new Date('9999-12-31'),
        path: '/',
        sameSite: 'strict'
    })
}

export const getDismissedMessage = () => {
    const cookies = new Cookies()
    return cookies.get('dismissedMessage')
}
